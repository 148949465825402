import React from "react";
import styled from "styled-components";
import ButtonCustom from "../../../../../../ui/Button/ButtonCustom";

const Container = styled.div`
  width: 100%;
  padding: 3px 0px;
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const Footer = ({
  isApplied,
  cancelHandler = (e) => {},
  applyHandler = (e) => {},
}) => {
  const cancel = () => {
    cancelHandler();
  };

  return (
    <Container>
      <ButtonCustom
        styleContainer={{ width: "max-content", height: "100%" }}
        fontSize={"11px"}
        title={"Tətbiq edilmiş filterləri ləğv et"}
        onClick={cancel}
        backgroundColor={"#710c04"}
        disabled={!isApplied}
      />

      <ButtonCustom
        styleContainer={{ width: "max-content", height: "100%" }}
        fontSize={"11px"}
        title={"Tətbiq et"}
        onClick={applyHandler}
      />

      {/* <button style={{ zIndex: 99 }} onClick={cancel}>
        aaa
      </button> */}
    </Container>
  );
};

export default Footer;
