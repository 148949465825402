import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import css from "./modal.module.css";
import { setegid } from "process";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddUser({ open, handleClose, getUsers }) {
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [fatherName, setFathterName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [nameValidation, setNameValidation] = React.useState("");
  const [surnameValidation, setSurnameValidation] = React.useState("");
  const [fatherNameValidation, setFatherNameValidation] = React.useState("");
  const [emailValidation, setEmailValidation] = React.useState("");
  const [roleValidation, setRoleValidation] = React.useState("");
  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  React.useEffect(() => {
    if (name.trim() === "") {
      setNameValidation("Ad yazın");
    }
    if (name.trim() != "") {
      setNameValidation("");
    }
  }, [name]);

  React.useEffect(() => {
    let validate = validateEmail(email);
    if (email.trim() === "") {
      setEmailValidation("Mail yazın");
    }
    if (email.trim() != "") {
      setEmailValidation("");
    }
    if (validate) {
      setEmailValidation("");
    }
    if (!validate) {
      setEmailValidation("Düzgün mail yazın");
    }
  }, [email]);
  React.useEffect(() => {
    if (role.trim() === "") {
      setRoleValidation("Rol qeyd edin");
    }
    if (role.trim() != "") {
      setRoleValidation("");
    }
  }, [role]);

  React.useEffect(() => {
    if (surname.trim() === "") {
      setSurnameValidation("Soyad yazın");
    }
    if (surname.trim() != "") {
      setSurnameValidation("");
    }
  }, [surname]);
  React.useEffect(() => {
    if (fatherName.trim() === "") {
      setFatherNameValidation("Ata adı yazın");
    }
    if (fatherName.trim() != "") {
      setFatherNameValidation("");
    }
  }, [fatherName]);

  const addUSer = async () => {
    let validate = validateEmail(email);
    if (name.trim() === "") {
      setNameValidation("Ad yazın");
    }
    if (surname.trim() === "") {
      setSurnameValidation("Soyad yazın");
    }
    if (fatherName.trim() === "") {
      setFatherNameValidation("Ata adı yazın");
    }
    if (email.trim() === "") {
      setEmailValidation("Mail yazın");
    }
    if (role.trim === "") {
      setRoleValidation("Rol qeyd edin");
    }
    if (!validate && email != "") {
      setEmailValidation("Düzgün mail yazın");
    } else {
      await fetch(process.env.REACT_APP_BASE_URL + "user/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          surname: surname,
          fatherName: fatherName,
          password: "111111",
          role: role,
          email: email,
        }),
      });
      getUsers();
      handleClose();
    }
  };

  React.useEffect(() => {
    setNameValidation("");
    setSurnameValidation("");
    setEmailValidation("");
    setRoleValidation("");
    setFatherNameValidation("");
    setName("");
    setSurname("");
    setFathterName("");
    setEmail("");
    setRole("");
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={css.modalBox}>
          <div className={css.validationCss}>
            <TextField
              id="outlined-basic"
              label="Ad"
              variant="outlined"
              value={name}
              className={css.text}
              onChange={(event) => setName(event.target.value)}
            />
            <span>{nameValidation}</span>
          </div>
          <div className={css.validationCss}>
            <TextField
              id="outlined-basic"
              label="Soyad"
              variant="outlined"
              className={css.text}
              value={surname}
              onChange={(event) => setSurname(event.target.value)}
            />
            <span>{surnameValidation}</span>
          </div>
          <div className={css.validationCss}>
            <TextField
              id="outlined-basic"
              label="Ata adı"
              variant="outlined"
              className={css.text}
              value={fatherName}
              onChange={(event) => setFathterName(event.target.value)}
            />
            <span>{fatherNameValidation}</span>
          </div>
          <div className={css.validationCss}>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              type="email"
              className={css.text}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <span>{emailValidation}</span>
          </div>
          <div className={css.validationCss}>
            <FormControl className={css.selectStyle}>
              <InputLabel id="demo-simple-select-label">rol</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                label="rol"
                onChange={handleChange}
              >
                <MenuItem value={"admin"}>admin</MenuItem>
                <MenuItem value={"superAdmin"}>super admin</MenuItem>
                <MenuItem value={"user"}>user</MenuItem>
              </Select>
            </FormControl>
            <span>{roleValidation}</span>
          </div>

          <Button variant="contained" onClick={addUSer} className={css.button}>
            Add
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
