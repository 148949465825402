import { KeyboardArrowDown } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getDescendantProp } from "../../../utils/helperFunctions";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Select = styled.div.attrs((props) => props)`
  width: 100%;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "#1B2635")};
  color: ${({ $_color }) => ($_color ? $_color : "#fff")};
  border: 1px solid;
  border-color: ${({ $isOpen, $hoverBorderColor, $borderColor }) =>
    $isOpen
      ? $hoverBorderColor
        ? $hoverBorderColor
        : "#fff"
      : $borderColor
      ? $borderColor
      : "#333"};
  border-radius: 5px;
  padding: 5px;
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: border-color 0.5s;

  &:hover {
    border-color: ${({ $hoverBorderColor }) =>
      $hoverBorderColor ? $hoverBorderColor : "#fff"};
  }
`;

const IconContainer = styled.div.attrs((props) => props)`
  width: 20px;
  height: 100%;
  transform: ${(props) => (props.$isOpen ? "rotate(0deg)" : "rotate(90deg)")};
  transition: all 0.3s;
`;

const ValueContainer = styled.div`
  width: calc(100% - 20px);
  height: 100%;
  text-align: center;
`;

const Options = styled.div.attrs((props) => props)`
  position: absolute;
  border-radius: 5px;
  z-index: 111;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "#1B2635")};
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  padding: 5px;
  left: 0;
  transition: all 0.3s;
  transform-origin: top;
  transform: scaleY(${({ $isOpen }) => ($isOpen ? "1" : "0")});
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? "visible" : "hidden")};
  height: 200px;
  width: 100%;
  overflow: scroll;
`;

const OptionItem = styled.div.attrs((props) => props)`
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 3px;
  color: ${({ $_color }) => ($_color ? $_color : "fff")};
  &:hover {
    background-color: ${({ $hoverBgColor }) =>
      $hoverBgColor ? $hoverBgColor : "#fff"};
    color: ${({ $hoverColor }) => ($hoverColor ? $hoverColor : "#3f3f3f")};
  }
`;

const Label = styled.span.attrs((props) => props)`
  background: ${({ $bgColor }) => ($bgColor ? $bgColor : "#1B2635")};
  opacity: 0.8;
  font-size: 10px;
  white-space: nowrap;
  padding: 0px 2px;
  will-change: transform;
`;

export default function SelectInput({
  styleOuterContainer,
  styleInnerContainer,
  styleValue,
  styleIconContainer,
  dropdownHeight,
  label,
  children,
  value,
  colors = {
    selectColor: undefined,
    selectBgColor: undefined,
    selectBorderColor: undefined,
    selectHoverBorderColor: undefined,
    optionsContBgColor: undefined,
    optionItemColor: undefined,
    optionItemHoverColor: undefined,
    optionItemHoverBgColor: undefined,
  },
  onChange = () => {}, // Function to handle selection
  multiple = false,
  data,
  itemValue,
  itemName,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(value || []); // Store multiple selected values
  const [displayValue, setDisplayValue] = useState("");
  const submenuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        submenuRef.current &&
        !submenuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    !value && setDisplayValue();
    !displayValue && setDisplayValue(value);
  }, [value]);

  // useEffect(() => {
  //   updateDisplayValue(); // Update display value when selected values change
  // }, [selectedValues]);

  const updateDisplayValue = (name) => {
    // Create a comma-separated string to display selected values
    if (multiple && Array.isArray(selectedValues)) {
      setDisplayValue(
        // selectedValues.map((val) => val.children || val).join(", ")
        name
      );
    } else {
      // setDisplayValue(selectedValues);
      setDisplayValue(name);
    }
  };

  const handleOptionClick = (optionValue, child) => {
    if (multiple) {
      // For multiple selection, toggle selected values
      const existingIndex = selectedValues.findIndex(
        (val) => val.value === optionValue.value
      );
      const updatedValues = [...selectedValues];

      if (existingIndex !== -1) {
        // If the option is already selected, remove it
        updatedValues.splice(existingIndex, 1);
        setSelectedValues(updatedValues);
      } else {
        // If the option is not selected, add it
        updatedValues[updatedValues.length] = optionValue;
        setSelectedValues(updatedValues);
      }
      onChange(updatedValues.map((item) => item.value));
    } else {
      // For single selection, set the selected value directly
      onChange(optionValue.value, child);
      setDisplayValue(optionValue.value);
      setSelectedValues([optionValue]);
      setIsOpen(false); // Close the dropdown after selection for single mode
    }
  };

  return (
    <Container style={styleOuterContainer}>
      <Select
        $bgColor={colors.selectBgColor}
        $borderColor={colors.selectBorderColor}
        $_color={colors.selectColor}
        $hoverBorderColor={colors.selectHoverBorderColor}
        $isOpen={isOpen}
        ref={buttonRef}
        style={styleInnerContainer}
        onClick={() => setIsOpen(!isOpen)}
        {...props}
      >
        {label && <Label $bgColor={colors.selectBgColor}>{label}</Label>}
        <ValueContainer style={styleValue}>{displayValue}</ValueContainer>
        <IconContainer style={styleIconContainer} $isOpen={isOpen}>
          <KeyboardArrowDown />
        </IconContainer>
      </Select>

      <Options
        $bgColor={colors.optionsContBgColor}
        $isOpen={isOpen}
        ref={submenuRef}
        $height={dropdownHeight}
      >
        {!data
          ? React.Children.map(children, (child) => {
              return (
                <OptionItem
                  $_color={colors.optionItemColor}
                  $hoverBgColor={colors.optionItemHoverBgColor}
                  $hoverColor={colors.optionItemHoverColor}
                  onClick={(e) => {
                    e.preventDefault();
                    handleOptionClick(child.props, child);
                    setIsOpen(false);
                    e.stopPropagation();
                  }}
                >
                  {child}
                </OptionItem>
              );
            })
          : data.map((item, index) => (
              <OptionItem
                $_color={colors.optionItemColor}
                $hoverBgColor={colors.optionItemHoverBgColor}
                $hoverColor={colors.optionItemHoverColor}
                key={item.toString() + index}
                onClick={(e) => {
                  e.preventDefault();
                  const value = itemValue
                    ? getDescendantProp(item, itemValue)
                    : item;
                  const name = Array.isArray(itemName)
                    ? itemName
                        .map((path) =>
                          path.includes("***")
                            ? path.slice(3)
                            : getDescendantProp(item, path) ||
                              getDescendantProp(item, path, true)
                        )
                        .join(" ")
                    : getDescendantProp(item, itemName);
                  // setDisplayValue(name);
                  updateDisplayValue(name);
                  setSelectedValues(value);
                  onChange(value, item);
                  setIsOpen(false);
                  // handleOptionClick(child.props, child);
                  e.stopPropagation();
                }}
              >
                <option
                  value={itemValue ? getDescendantProp(item, itemValue) : item}
                >
                  {Array.isArray(itemName)
                    ? itemName
                        .map((path) =>
                          path.includes("***")
                            ? path.slice(3)
                            : getDescendantProp(item, path) ||
                              getDescendantProp(item, path, true)
                        )
                        .join(" ")
                    : getDescendantProp(item, itemName)}
                </option>
              </OptionItem>
            ))}
      </Options>
    </Container>
  );
}
