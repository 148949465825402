import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import ButtonCustom from "../../ui/Button/ButtonCustom";
import Input from "../../ui/Input/Input";
import SelectInput from "../../ui/Select/SelectInput";
import ColumnsVisibility from "./ColumnsVisibility";
import Filter from "./Filter/Filter";
import css from "../customTable.module.css";

const arr = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
const rowArr = [5, 10, 15, 20, 25, 50, 100];

const Container = styled.div`
  padding: 10px 0px;
  width: 100%;
  height: auto;
`;
const Div = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
`;

export default function Toolbar({
  fontSize,
  fontSizeHandler,
  limit,
  setLimit,
  columns = { data: [], setData: () => {} },
  applyFiltersHandler,
  cancelAppliedFilters,
  quickFilters,
  downloadHandler,
  addLeftContButtons,
  addRightContButtons,
  hideFilter = false,
  hideColumnVisibility = false,
  hideDownload = false,
  setColWidth,
  colWidth,
  setOpen,
  setOpenSchemas,
  isSchema,
}) {
  const fontSizeChangeHandler = (value) => {
    fontSizeHandler(+value);
  };

  const rowCountCahngeHandler = (value) => {
    console.log(value);
    setLimit(+value);
  };
  const [openDiv, setOpenDiv] = useState(false);

  const FontSizer = (
    <div
      style={{
        display: "flex",
        width: "100px",
      }}
    >
      <SelectInput
        dropdownHeight={"300px"}
        value={fontSize}
        onChange={fontSizeChangeHandler && fontSizeChangeHandler}
        label={"Şrift ölçüsü: "}
      >
        {arr.map((item, index) => (
          <option
            key={`${item}${index}`}
            style={{
              textAlign: "center",
              width: "100%",
              backgroundColor: item === fontSize && "rgba(255,255,255,0.5)",
            }}
            value={item}
          >
            {item}
          </option>
        ))}
      </SelectInput>
    </div>
  );

  const RowCounter = (
    <div
      style={{
        display: "flex",
        width: "100px",
        marginLeft: "5px",
      }}
    >
      <SelectInput
        dropdownHeight={"auto"}
        value={limit}
        onChange={rowCountCahngeHandler && rowCountCahngeHandler}
        label={"Sətir sayı: "}
      >
        {rowArr.map((item, index) => (
          <option
            key={`${item}${index}`}
            style={{
              textAlign: "center",
              width: "100%",
              backgroundColor: item === limit && "rgba(255,255,255,0.5)",
            }}
            value={item}
          >
            {item}
          </option>
        ))}
      </SelectInput>
    </div>
  );

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenDiv(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const buttonRef = useRef(null);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, buttonRef);

  return (
    <Container>
      <div style={{ display: "flex", height: "35px" }}>
        <Div>
          {!hideColumnVisibility && (
            <div style={{ marginRight: "5px" }}>
              <ColumnsVisibility
                width={"80px"}
                data={columns.data}
                setData={columns.setData}
              />
            </div>
          )}
          {!hideFilter && (
            <div>
              <Filter
                columns={columns.data}
                applyFiltersHandler={applyFiltersHandler}
                cancelAppliedFilters={cancelAppliedFilters}
                quickFilters={quickFilters}
              />
            </div>
          )}
          {addLeftContButtons?.map((item, index) => (
            <div key={`${item} ${index}`}>{item}</div>
          ))}
        </Div>

        <Div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "relative",
          }}
        >
          {!hideDownload && (
            <div
              ref={wrapperRef}
              style={{
                height: "35px ",
              }}
            >
              <ButtonCustom
                onClick={() => {
                  if (isSchema) {
                    setOpen(true);
                  } else {
                    setOpenDiv(!openDiv);
                  }
                }}
                width={"100%"}
              >
                {isSchema ? "Şablon yarat" : "Əməliyyat yarat"}
              </ButtonCustom>
              {openDiv && (
                <div className={css.buttonsDiv}>
                  <button
                    onClick={() => {
                      setOpen(true);
                      setOpenDiv(false);
                    }}
                  >
                    Yeni əməliyyat
                  </button>
                  <button
                    onClick={() => {
                      setOpenSchemas(true);
                      setOpenDiv(false);
                    }}
                  >
                    Şablondan
                  </button>
                </div>
              )}
            </div>
          )}

          {addRightContButtons?.map((item, index) => (
            <div key={`${item} ${index}`}>{item}</div>
          ))}
        </Div>
      </div>

      <div style={{ marginTop: "10px", display: "flex" }}>
        {FontSizer}
        {RowCounter}
        <Input
          onChange={(e) => setColWidth(+e.target.value)}
          label={"Sütun eni: "}
          styleContainer={{ width: "110px", marginLeft: "5px" }}
          value={colWidth}
          type={"number"}
          min={50}
        />
      </div>
    </Container>
  );
}
