import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import css from "./modal.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function SchemaModal({
  openSchemas,
  handleCloseSchema,
  setSelectedSchema,
  setOpen,
  setChangeSchema,
  changeSchema,
}) {
  const [input, setInput] = React.useState("");
  const [filteredDatas, setfilteredDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);

  const getDatas = () => {
    fetch(process.env.REACT_APP_BASE_URL + "operation/getAllSchema", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDatas(data);
        setfilteredDatas(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterDatas = () => {
    const lowerInput = input.toLocaleLowerCase("az-AZ");
    const filtered = datas.filter((element) => {
      if (element && element.name) {
        return element.name.toLowerCase("az-AZ").includes(lowerInput);
      }
    });
    setfilteredDatas(filtered);
  };

  React.useEffect(() => {
    getDatas();
  }, []);

  React.useEffect(() => {
    filterDatas();
  }, [input]);

  const setSelected = (name) => {
    setSelectedSchema(name);
    handleCloseSchema();
    setChangeSchema(!changeSchema);
    setOpen(true);
  };

  return (
    <div>
      <Modal
        open={openSchemas}
        onClose={handleCloseSchema}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={css.schemaModal}>
          <div className={css.searchCont}>
            <input
              placeholder="Axtar..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className={css.searchInput}
            />
          </div>
          <div className={css.tableContainer}>
            {filteredDatas &&
              filteredDatas.map((element) => {
                return (
                  element.name && (
                    <div
                      className={css.textContainerSchema}
                      onClick={() => setSelected(element.name)}
                    >
                      <span>
                        <span className="head">Ad:</span> {element.name}
                      </span>
                      <span>
                        <span className="head">Debet hesab no:</span>
                        {element.debetAccNo}
                      </span>
                      <span>
                        <span className="head">Debet adı:</span>{" "}
                        {element.debetText}
                      </span>
                      <span>
                        <span className="head">Sub debet hesab no:</span>
                        {element.subDebetAccNo}
                      </span>
                      <span>
                        <span className="head">
                          Detallı sub debet hesab no:
                        </span>{" "}
                        {element.subSubDebetAccNo}
                      </span>
                      <span>
                        <span className="head">Kredit hesab no:</span>
                        {element.creditAccNo}
                      </span>
                      <span>
                        <span className="head">Kredit adı:</span>
                        {element.creditText}
                      </span>
                      <span>
                        <span className="head">Sub kredithesab no:</span>
                        {element.subCreditAccNo}
                      </span>
                      <span>
                        <span className="head">
                          Detallı sub kredit hesab no:
                        </span>
                        {element.subSubCreditAccNo}
                      </span>
                    </div>
                  )
                );
              })}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
