import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import TableComponent from "../../tables/table/Table";
import AddUser from "../../../components/modal/addUser";
import { useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

function User() {
  const [user, setUser] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleted, setDeleted] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const getUsers = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "user/allUsers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUser(data);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const deleteUser = async (id, token, password) => {
    await fetch(process.env.REACT_APP_BASE_URL + "user/remove", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: id,
        token: token,
        password: password,
      }),
    }).then(async (response) => {
      if (response.ok) {
        setDeleted(!deleted);
      } else if (!response.ok) {
        const data = await response.json();
        alert(data.message);
      }
    });

    getUsers();
  };
  return (
    <React.Fragment>
      <AddUser open={open} handleClose={handleClose} getUsers={getUsers} />
      <Typography variant="h3" gutterBottom display="inline"></Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TableComponent
            data={user || []}
            setOpen={setOpen}
            getUsers={getUsers}
            deleteFunction={deleteUser}
            deleted={deleted}
            isDelete={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default User;
