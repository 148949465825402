import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import style from "./table.module.css";
import image from "../../../images/red-trash-can-icon.png";
import DeleteModal from "../../../components/modal/DeleteModal";
import { Button } from "@mui/material";

function TableComponent({ data, setOpen, deleteFunction, deleted, isDelete }) {
  const [showModal, setShowModal] = useState(false);
  const [operationId, setOperationId] = useState();
  const handleClose = () => {
    setShowModal(false);
  };
  if (!data || data.length === 0) {
    return (
      <>
        <div className={style.buttonContainer}>
          {isDelete && (
            <Button variant="contained" onClick={() => setOpen(true)}>
              ƏLAVƏ ET
            </Button>
          )}
        </div>
      </>
    );
  }

  const columns = Object.keys(data[0]).filter(
    (column) => column != "_id" && column != "__v" && column != "removed"
  );

  return (
    <>
      <DeleteModal
        open={showModal}
        handleClose={handleClose}
        id={operationId}
        deleteFunction={deleteFunction}
        deleted={deleted}
      />
      <TableContainer className={style.tableContainer} component={Paper}>
        <div className={style.tableWrapper}>
          <div className={style.buttonContainer}>
            {isDelete && (
              <Button variant="contained" onClick={() => setOpen(true)}>
                ƏLAVƏ ET
              </Button>
            )}
          </div>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column} style={{ paddingLeft: "20px" }}>
                    <h3>
                      {column === "fatherName"
                        ? "Ata adı"
                        : column === "email"
                        ? "Email/İstifadəçi adı"
                        : column === "name"
                        ? "Ad"
                        : column === "surname"
                        ? "Soyad"
                        : column === "role"
                        ? "Rol"
                        : column === "operationNo"
                        ? "Əməliyyat nömrəsi"
                        : column === "operationDate"
                        ? "Əməliyyat tarixi"
                        : column === "creditSerial"
                        ? "Kredit seria"
                        : column === "creditText"
                        ? "Kredit"
                        : column === "debetSerial"
                        ? "Debet seria"
                        : column === "debetText"
                        ? "Debet"
                        : column === "destination"
                        ? "Təyinat"
                        : column === "amount"
                        ? "Məbləğ"
                        : column === "user"
                        ? "İstifadəçi"
                        : column}
                    </h3>
                  </TableCell>
                ))}
                <TableCell>{isDelete && <h3>Operatorlar</h3>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell
                      key={column}
                      align="left"
                      style={{
                        paddingLeft: "20px",
                        maxWidth: "70px",
                        overflow: "hidden",
                      }}
                    >
                      {row[column]}
                    </TableCell>
                  ))}
                  <TableCell>
                    <div>
                      <button
                        className={style.delete}
                        onClick={() => {
                          setOperationId(row._id);
                          setShowModal(true);
                        }}
                      >
                        {isDelete && <img src={image} />}
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </>
  );
}

export default TableComponent;
