import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import TableComponent from "../../tables/table/Table";
import AddOperation from "../../../components/modal/addOperation";
import { useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

export default function DeletedOperations() {
  const [operation, setOperation] = useState(null);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const getOperation = () => {
    fetch(process.env.REACT_APP_BASE_URL + "operation/getAllRemoved", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          const newData = data.map((element) => {
            const updatedDate = new Date(element.operationDate);
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            };
            const formattedDate = new Intl.DateTimeFormat(
              "en-GB",
              options
            ).format(updatedDate);

            element.operationDate = formattedDate;
          });
          setOperation(newData);
        }
        setOperation(data);
      });
  };

  useEffect(() => {
    getOperation();
  }, []);

  const recoverOperation = async (id) => {
    await fetch(process.env.REACT_APP_BASE_URL + "operation/recover", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        operationId: id,
      }),
    });

    getOperation();
  };

  return (
    <React.Fragment>
      <AddOperation
        open={open}
        handleClose={handleClose}
        setOpen={setOpen}
        getAllOperations={getOperation}
      />
      <Typography variant="h3" gutterBottom display="inline">
        Silinən əməliyyatlar
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TableComponent
            data={operation || []}
            setOpen={setOpen}
            deleteFunction={recoverOperation}
            deleted={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
