import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OPERATORS_CONTAINING } from "../../../../../../../../constants";

const Container = styled.div`
  width: 100%;
`;

const CustomSelect = styled.div`
  position: relative;
  width: 100%; /* Adjust the width as needed */

  select {
    appearance: none;
    outline: 0;
    cursor: pointer;
    width: 100%;
    background-color: #1b2635;
    padding: 5px 10px;
    border: 1px solid #333;
    border-radius: 5px;
    color: white;
    transition: border-color 0.3s ease-in-out;
    position: relative;
    z-index: 1;

    &:focus,
    &:hover {
      border-color: #fff;
    }
  }

  /* Styling the dropdown indicator */
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 10px;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: white transparent transparent;
    pointer-events: none;
    z-index: 2;
  }
`;

const Input = styled.input`
  outline: 0;
  width: 100%;
  background-color: #1b2635;
  padding: 5px 10px;
  border: 1px solid #333;
  border-radius: 5px;
  color: white;
  transition: border-color 0.3s ease-in-out;
  position: relative;
  z-index: 1;

  &:focus,
  &:hover {
    border-color: #fff;
  }
`;

export default function ValueInput({
  filterType,
  onChange,
  filter,
  fetchHandler = undefined,
  fetchHandler2 = undefined,
  dataGridName,
  structureKey,
  topPartOfStructKey,
  column,
  values,
  disabled,
  value,
  triggerFetching = true,
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchHandler && triggerFetching && fetchOptions(fetchHandler);
    values && setOptions(values);
  }, [fetchHandler, values, triggerFetching]);

  const fetchOptions = async (handler) => {
    try {
      const data = await handler();
      data && setOptions(data);
    } catch (error) {
      console.log("err", error);
    }
  };

  const generateValueInput = () => {
    switch (filterType) {
      case OPERATORS_CONTAINING.TEXT:
        return (
          <Input
            style={{ width: "100%" }}
            type="text"
            onChange={onChange}
            value={value}
            disabled={disabled}
            placeholder="Dəyər daxil edin..."
          />
        );
      case OPERATORS_CONTAINING.OPTION:
        return (
          <CustomSelect>
            <select
              value={value}
              style={{ width: "100%" }}
              onChange={onChange}
              disabled={disabled}
            >
              <option hidden>Dəyər seçin...</option>
              {options?.map((item, index) => (
                <option
                  key={(item.key ? item.key : item.value) + index}
                  value={item.key ? item.key : item.value}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </CustomSelect>
        );
      case OPERATORS_CONTAINING.DATE:
        return (
          <Input
            disabled={disabled}
            type={"date"}
            onChange={onChange}
            value={value}
          />
        );
      case OPERATORS_CONTAINING.OPTION_MULTIPLE:
        return (
          <CustomSelect>
            <select
              multiple={true}
              value={value}
              style={{ width: "100%" }}
              onChange={onChange}
              disabled={disabled}
            >
              <option hidden>Dəyər seçin...</option>
              {options?.map((item, index) => (
                <option key={item.key + index} value={item.key}>
                  {item.name}
                </option>
              ))}
            </select>
          </CustomSelect>
        );
      case OPERATORS_CONTAINING.SINGLE_OPTION:
        return (
          <CustomSelect>
            <select
              value={value}
              style={{ width: "100%" }}
              onChange={onChange}
              disabled={disabled}
            >
              <option hidden>Dəyər seçin...</option>
              {options?.map((item, index) => (
                <option key={item.key + index} value={item.key}>
                  {item.name}
                </option>
              ))}
            </select>
          </CustomSelect>
        );
      default:
        return noneStandartCase();
    }
  };

  const noneStandartCase = (arr) => {
    if (!arr && arr?.length === 0) {
      return "input";
    }

    return (
      <CustomSelect>
        <select
          value={value}
          style={{ width: "100%" }}
          onChange={onChange}
          disabled={disabled}
        >
          <option hidden>Dəyər seçin...</option>
          {arr?.map((item, index) => (
            <option key={item.key + index} value={item.key}>
              {item.name}
            </option>
          ))}
        </select>
      </CustomSelect>
    );
  };

  return generateValueInput();
}
