import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import css from "./modal.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DeleteModal({
  open,
  handleClose,
  id,
  deleteFunction,
  deleted,
}) {
  const [password, setPassword] = React.useState("");
  const deleteOpearation = () => {
    deleteFunction(id, localStorage.getItem("token"), password);
  };

  React.useEffect(() => {
    handleClose();
  }, [deleted]);

  React.useEffect(() => {
    setPassword("");
  }, [open]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={css.deleteModal}>
          <input
            className={css.passwordInput}
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            type="password"
          />
          <Button
            variant="contained"
            onClick={() => {
              if (password) {
                deleteOpearation();
              } else {
                alert("plese enter password");
              }
            }}
            className={css.deleteButton}
          >
            Delete
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
