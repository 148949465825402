import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import css from "./modal.module.css";
import { TextSnippetSharp, Textsms } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddAccount({
  open,
  handleClose,
  data,
  handleChange,
  selected,
}) {
  const [input, setInput] = React.useState("");
  const [filteredDatas, setfilteredDatas] = React.useState([]);

  const filterDatas = () => {
    const lowerInput = input.toLocaleLowerCase("az-AZ");
    const filteredTexts = [];
    data.forEach((element) => {
      const filteredHeaders = element.headers
        .map((header) => {
          const filteredTexts = header.texts.filter(({ text }) =>
            text.toLocaleLowerCase("az-AZ").includes(lowerInput)
          );

          if (filteredTexts.length > 0) {
            return {
              name: header.name,
              texts: filteredTexts,
            };
          } else {
            return null;
          }
        })
        .filter((header) => header !== null);

      if (filteredHeaders.length > 0) {
        filteredTexts.push({
          mainHeader: element.mainHeader,
          headers: filteredHeaders,
        });
      }
    });

    setfilteredDatas(filteredTexts);
  };

  React.useEffect(() => {
    setInput("");
  }, [open]);

  React.useEffect(() => {
    filterDatas();
  }, [input, data]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={css.modalTable}>
          <input
            placeholder="Axtar..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={css.searchInput}
          />
          <div className={css.tableContainer}>
            {filteredDatas &&
              filteredDatas.map((mainElement) => {
                return (
                  <div>
                    <div className={css.mainHeader}>
                      <h1>{mainElement.mainHeader}</h1>
                    </div>
                    {mainElement.headers.map((headerElement) => {
                      return (
                        <div>
                          <div className={css.header}>
                            <h2>{headerElement.name}</h2>
                          </div>
                          {headerElement.texts.map((element) => {
                            return (
                              <div
                                className={css.textContainer}
                                onClick={() => {
                                  handleChange(
                                    element.serial,
                                    mainElement.mainHeader,
                                    headerElement.name
                                  );
                                }}
                              >
                                <div className={css.serial}>
                                  <h3>{element.serial}</h3>
                                </div>
                                <div className={css.text}>
                                  <h3>{element.text}</h3>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
