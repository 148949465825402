export const getSchemas = (setSchemas, limit, offset, setTotalRows) => {
  fetch(process.env.REACT_APP_BASE_URL + "operation/getAllData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      offset: offset,
      limit: limit,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      let arr = [];
      if (data) {
        setTotalRows(data.operationSchemaLength);
        arr = data.filteredSchema;
        arr.map((element) => {
          const updatedDate = new Date(element.date);
          const options = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          };
          const formattedDate = new Intl.DateTimeFormat(
            "en-GB",
            options
          ).format(updatedDate);
          element.date = formattedDate;
        });
      }
      console.log(arr);
      setSchemas(arr);
    });
};
