// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const OPERATORS_CONTAINING = {
  DATE: [
    { key: "isAfter", name: "is after" },
    { key: "isBefore", name: "is before" },
    { key: "is", name: "is" },
    { key: "isNot", name: "is not" },
  ],
  TEXT: [
    {
      key: "contains",
      name: "contains",
    },
    {
      key: "startsWith",
      name: "starts with",
    },
    {
      key: "endsWith",
      name: "ends with",
    },
    { key: "isEmpty", name: "is empty" },
    {
      key: "isNotEmpty",
      name: "is not empty",
    },
  ],
  OPTION: [
    {
      key: "equalsTo",
      name: "equals to",
    },
    {
      key: "notEqual",
      name: "not equal",
    },
  ],
  OPTION_MULTIPLE: [
    {
      key: "equal",
      name: "equal",
    },
  ],
  TIME: [
    {
      key: "day",
      name: "gün",
    },
    {
      key: "week",
      name: "həftə",
    },
    {
      key: "month",
      name: "ay",
    },
    {
      key: "year",
      name: "il",
    },
  ],
  SINGLE_OPTION: [
    {
      key: "equalsTo",
      name: "equals to",
    },
  ],
};
