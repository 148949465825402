import { ManageSearch } from "@mui/icons-material";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import FilterModel from "../../models/table/Filter/FilterModel";
import { getDescendantProp } from "../../utils/helperFunctions";
import ValueInput from "./parts/Filter/parts/dropdown/body/filterItem/ValueInput";
import LoadingTable from "./parts/LoadingTable";
import TableFooter from "./parts/TableFooter";
import Toolbar from "./parts/Toolbar";
import moment from "moment";

const Container = styled.div`
  width: 100%;
  overflow: visible;
  border: 2px solid rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 5px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const TableComponent = styled.table`
  border-collapse: collapse;
  border-spacing: 0px;
  border-radius: 5px;
  padding: 10px 40px;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
  min-width: 100%;
  display: table;
`;

const Col = styled.col`
  padding: 5px 15px;
  border: 1px solid rgba(255, 255, 255, 0.025);
  display: table-column;
  overflow: auto;
  min-width: 50px;
`;

const CellCont = styled.div`
  display: flex;
  justify-content: center;
  row-gap: 5px;
  align-items: center;
  text-align: center;
  min-height: 20px;
  position: relative;
  text-overflow: ellipsis;
`;

const TH = styled.th`
  resize: horizontal;
  white-space: nowrap;
  padding: 5px 5px;
  text-align: center;
  font-weight: 500;
  text-overflow: ellipsis;
  cursor: default;
  min-width: fit-content;
  overflow: hidden;
  color: #f1f1f1;
  width: ${({ $width }) => $width}px;
`;
// width: ${({ $isStatic }) => $isStatic && "200px"};

const Td = styled.td`
  padding: 10px;
  margin: 0;
  font-weight: 100;
`;

const Tr = styled.tr`
  border: 1px solid rgba(255, 255, 255, 0.05);
`;

const Colgroup = styled.colgroup``;

const Thead = styled.thead`
  border-radius: 3px;
  background-color: rgba(21, 27, 76, 0.7);
  border-bottom: 1px solid rgba(41, 47, 96, 1);
`;

const TableWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  overflow: auto;
`;

function Table({
  columns,
  data,
  totalRows,
  offset,
  setOffset,
  limit,
  setLimit,
  isLoading,
  graphicName,
  applyFiltersHandler,
  cancelAppliedFilters,
  downloadHandler,
  addLeftContButtons,
  addRightContButtons,
  hideColumnVisibility = false,
  hideDownload = false,
  hideFilter = false,
  setOpen,
  setOpenSchemas,
  deleteFunction,
  deleted,
  isDelete,
  isSchema,
}) {
  const initialInputsValues = columns?.reduce((acc, column) => {
    if (column.filterType?.length > 0) {
      return {
        ...acc,
        [column.path]: new FilterModel({
          column: column.path,
          operator: column.filterType[0].key,
          value: "",
        }),
      };
    }
    return acc;
  }, {});

  const [showColSearch, setShowColSearch] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const [cols, setCols] = useState([]);
  const [colWidth, setColWidth] = useState(200);

  const [quickFilters, setQuickFilters] = useState(initialInputsValues);

  useEffect(() => {
    const sortedColumns = [...columns].sort((a, b) => {
      if (a.isStaticColumn && !b.isStaticColumn) {
        return 1; // Move 'a' (isStaticColumn: true) to the end
      } else if (!a.isStaticColumn && b.isStaticColumn) {
        return -1; // Keep 'b' (isStaticColumn: true) before 'a'
      } else {
        return 0; // Maintain the current order
      }
    });

    setCols(sortedColumns);
  }, [columns]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    e.preventDefault();

    const oldIndex = parseInt(e.dataTransfer.getData("index"));

    if (cols[oldIndex].isStaticColumn) return;

    const updatedCols = [...cols];
    const [movedCol] = updatedCols.splice(oldIndex, 1);

    updatedCols.splice(newIndex, 0, movedCol);

    setCols(updatedCols);
  };

  // const generateQuickFilterState = () => {
  //   const arr = columns?.map((col) => {
  //     return {
  //       id: col.path,
  //       column: col.path,
  //       operator: col?.filterType && col.filterType[0]?.key,
  //       isQuickFilter: true,
  //       value: "",
  //     };
  //   });
  //   setQuickFilters(arr);
  // };

  const singleFilterHandler = (column, event) => {
    // const arr = [...quickFilters];
    // const newFilter = event.target.value && {
    //   id: column.path,
    //   column: column.path,
    //   operator: column.filterType[0]?.key,
    //   value: event.target.value,
    //   isQuickFilter: true,
    // };
    // // if (arr.lengt === 0) {
    // //   arr.push(newFilter);
    // //   return;
    // // }
    // const index = arr.findIndex((item) => item.id === column.path);
    // if (index > -1) {
    //   arr[index] = newFilter;
    // } else {
    //   arr.push(newFilter);
    // }
    // setQuickFilters(arr);
    setQuickFilters((prevValues) => ({
      ...prevValues,
      [column.path]: new FilterModel({
        // ...prevValues[column.path],
        column: column.filterColumnKey ? column.filterColumnKey : column.path,
        operator: column.filterType[0].key,
        value: event.target.value,
      }),
    }));
  };

  const emptyRowRender = () => {
    const emptyCount = limit && (isLoading ? limit : limit - data?.length);

    const tableRows = [];

    if (emptyCount <= 0) return;

    for (let i = 0; i < emptyCount; i++) {
      tableRows.push(
        <Tr key={"empty" + i}>
          <Td>
            <CellCont></CellCont>
          </Td>
        </Tr>
      );
    }
    return tableRows;
  };

  const cancelAppliedFiltersHandler = async () => {
    setQuickFilters(initialInputsValues);
    await cancelAppliedFilters();
  };

  const sendColumnsForColumns = async () => {
    await downloadHandler(cols);
  };

  const formatDate = (dateString) => {
    const date = moment(dateString);
    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  };
  return (
    <Container>
      <Toolbar
        columns={{ data: cols, setData: setCols }}
        fontSize={fontSize}
        fontSizeHandler={(value) => setFontSize(value)}
        limit={limit}
        setLimit={(value) => {
          setOffset(0);
          setLimit(value);
        }}
        applyFiltersHandler={applyFiltersHandler}
        cancelAppliedFilters={cancelAppliedFiltersHandler}
        quickFilters={quickFilters}
        downloadHandler={sendColumnsForColumns}
        addLeftContButtons={addLeftContButtons}
        addRightContButtons={addRightContButtons}
        hideColumnVisibility={hideColumnVisibility}
        hideFilter={hideFilter}
        hideDownload={hideDownload}
        setColWidth={setColWidth}
        colWidth={colWidth}
        setOpen={setOpen}
        setOpenSchemas={setOpenSchemas}
        isSchema={isSchema}
      />
      {
        <div style={{ position: "relative" }}>
          <TableWrapper>
            <TableContainer>
              <TableComponent style={{ position: isLoading && "relative" }}>
                <Colgroup>
                  {cols?.map(
                    (col, index) =>
                      col.isShow && (
                        <Col
                          key={index}
                          style={{ minWidth: col.minWidth && col.minWidth }}
                        />
                      )
                  )}
                </Colgroup>
                <Thead>
                  <Tr>
                    {cols?.map(
                      (item, index) =>
                        item?.isShow && (
                          <TH
                            $isStatic={item.isStaticColumn}
                            $width={colWidth}
                            key={index}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, index)}
                          >
                            <div
                              style={{
                                width: "100%",
                                textOverflow: "ellipsis",
                                padding: "0px 5px",
                                whiteSpace: "nowrap",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  alignItems: "center",
                                  fontSize: fontSize,
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                {!hideFilter && !item.disableColSearch && (
                                  <ManageSearch
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      setShowColSearch(!showColSearch)
                                    }
                                  />
                                )}
                                {item.headerName}
                              </div>
                              {!hideFilter && (
                                <div
                                  style={{
                                    width: "100%",
                                    transition: "all 0.5s",
                                    height: showColSearch ? "40px" : "0px",
                                    opacity: showColSearch ? 1 : 0,
                                    overflow: showColSearch ? "hidden" : "none",
                                    marginTop: showColSearch && "7px",
                                  }}
                                >
                                  {!item.disableColSearch && (
                                    <ValueInput
                                      key={item.path}
                                      column={item}
                                      values={
                                        item.filterValues && item.filterValues
                                      }
                                      fetchHandler={
                                        item?.fetchFilterValues &&
                                        item?.fetchFilterValues
                                      }
                                      filterType={item.filterType}
                                      onChange={(e) => {
                                        singleFilterHandler(item, e);
                                      }}
                                      value={quickFilters[item.path]?.value}
                                      triggerFetching={showColSearch}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </TH>
                        )
                    )}
                  </Tr>
                </Thead>
                <tbody>
                  {!isLoading &&
                    Array.isArray(data) &&
                    data.length > 0 &&
                    data?.map((row, rowIndex) => (
                      <Tr key={rowIndex}>
                        {cols?.map(
                          (col, colIndex) =>
                            col?.isShow && (
                              <Td key={colIndex}>
                                {col.renderCell ? (
                                  col.renderCell(row, col)
                                ) : (
                                  <CellCont>
                                    {col.hasCellButton && col.cellButtonProps && (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          width: "20%",
                                        }}
                                        onClick={() =>
                                          col.cellButtonProps.onClick(
                                            col.cellButtonProps.value(row)
                                          )
                                        }
                                      >
                                        {col?.cellButtonProps?.icon}
                                      </div>
                                    )}
                                    <div
                                      className={graphicName}
                                      style={{
                                        fontSize: fontSize,
                                        textOverflow: "ellipsis",
                                        width: "250px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {col.path === "dob"
                                        ? formatDate(
                                            getDescendantProp(row, col.path)
                                          )
                                        : col.getValue
                                        ? col.getValue(row, col)
                                        : getDescendantProp(row, col.path) ||
                                          "-"}
                                    </div>
                                  </CellCont>
                                )}
                              </Td>
                            )
                        )}
                      </Tr>
                    ))}
                  {emptyRowRender()}
                </tbody>
                {isLoading && <LoadingTable />}
              </TableComponent>
            </TableContainer>
          </TableWrapper>

          <TableFooter
            totalRows={totalRows}
            limit={limit}
            offset={offset}
            paginationHandler={(page) => {
              const value = (+page - 1) * limit;
              setOffset(value);
            }}
          />
        </div>
      }
    </Container>
  );
}

export default memo(Table);
