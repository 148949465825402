import * as React from "react";
import Box from "@mui/material/Box";
import ButtonMaterial from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { TextField } from "@mui/material";
import css from "./modal.module.css";
import AddAccount from "./tableModal";
import arrow from "../../images/arrow.png";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";
import {
  AssuredWorkloadSharp,
  DesktopAccessDisabledSharp,
  Schema,
} from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddOperation({
  setOpen,
  open,
  handleClose,
  getOperation,
  selectedSchema,
  changeSchema,
  isSchema,
}) {
  const [selectedDebet, setSelectedDebet] = React.useState();
  const [selectedSubDebet, setSelectedSubDebet] = React.useState("");
  const [selectedCredit, setSelectedCredit] = React.useState();
  const [selectedSubCredit, setSelectedSubCredit] = React.useState("");
  const [selectedDebetMainHeader, setSelectedDebetMainHeader] =
    React.useState();
  const [selectedDebetHeader, setSelectedDebetHeader] = React.useState();
  const [selectedCreditMainHeader, setSelectedCreditMainHeader] =
    React.useState();
  const [selectedCreditHeader, setSelectedCreditHeader] = React.useState();
  const [accounts, setAccounts] = React.useState([]);

  const [selectedOperation, setSelectedOperation] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [enable, setEnable] = React.useState(true);
  const [destination, setDestination] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [openDebet, setOpenDebet] = React.useState(false);
  const [openCredit, setOpenCredit] = React.useState(false);
  const [saveSchema, setSaveSchema] = React.useState(false);
  const handleCloseDebet = () => {
    setOpenDebet(false);
  };
  const [operationValidation, setOperationValidation] = React.useState("");
  const [debetValidation, setDebetValidation] = React.useState("");
  const [creditValidation, setCreditValidation] = React.useState("");
  const [ammountValidation, setAmmountValidation] = React.useState("");
  const [destinationValidation, setDestionationValidation] = React.useState("");
  const [subSubAccount, setSubSubAccount] = React.useState([]);
  const [subSubCreditAccount, setSubSubCreditAccount] = React.useState([]);
  const [selectedSubSubCreditAcc, setSelectedSubSubCreditAcc] =
    React.useState("");
  const [selectedDebetSubSubAcc, setSelectedSubSubDebetAcc] =
    React.useState("");

  const today = dayjs();
  const minDate = dayjs("2022-01-01");

  const [subAccounts, setSubAccounts] = React.useState([]);
  const [creditSubAccount, setCreditSubAccount] = React.useState([]);
  const [nameSchema, setNameSchema] = React.useState("");
  const [name, setName] = React.useState("");

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  React.useEffect(() => {
    if (selectedSchema) {
      fetch(process.env.REACT_APP_BASE_URL + "operation/getSchemaByName", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: selectedSchema,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          getSubAccounts(selectedDebet, "debet");
          console.log(data);
          setDestination(data.destination);
          setSelectedDebet(data.debetAccNo);
          setSelectedCredit(data.creditAccNo);
          setSelectedSubDebet(data.subDebetAccNo);
          setSelectedSubCredit(data.subCreditAccNo);
          setSelectedSubSubDebetAcc(data.subSubDebetAccNo);
          setSelectedSubSubCreditAcc(data.subSubCreditAccNo);
          getSubAccounts(data.debetAccNo, "debet");
          getSubAccounts(data.creditAccNo, "credit");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [changeSchema]);

  const addSchema = () => {
    if (nameSchema) {
      fetch(process.env.REACT_APP_BASE_URL + "operation/addSchema", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: nameSchema,
          debetAccNo: selectedDebet,
          creditAccNo: selectedCredit,
          subDebetAccNo: selectedSubDebet,
          subSubDebetAccNo: selectedDebetSubSubAcc,
          subCreditAccNo: selectedSubCredit,
          subSubCreditAccNo: selectedSubSubCreditAcc,
          destination: destination,
          date: today,
          token: localStorage.getItem("token"),
          debetAccMainHeader: selectedDebetMainHeader,
          debetAccHeaderName: selectedDebetHeader,
          creditAccMainHeader: selectedCreditMainHeader,
          creditAccHeaderName: selectedCreditHeader,
        }),
      })
        .then(() => {
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addDebetSubSub = (e) => {
    e.preventDefault();
    if (name) {
      fetch(process.env.REACT_APP_BASE_URL + "account/addSubSub", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serial: selectedSubDebet,
          mySubSubAccount: name,
        }),
      }).then(() => {
        getSubSubAccounts(selectedSubDebet, "debet");
      });
    } else {
      alert("Account əlavə edin");
    }
    setName("");
  };
  const addCreditSubSub = (e) => {
    e.preventDefault();
    if (name) {
      fetch(process.env.REACT_APP_BASE_URL + "account/addSubSub", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serial: selectedSubCredit,
          mySubSubAccount: name,
        }),
      }).then(() => {
        getSubSubAccounts(selectedSubCredit, "credit");
      });
    } else {
      alert("Account əlavə edin");
    }
    setName("");
  };

  const addDebetSub = (e) => {
    e.preventDefault();
    if (name) {
      fetch(process.env.REACT_APP_BASE_URL + "account/addSub", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serial: selectedDebet,
          mySubAccount: name,
        }),
      }).then(() => {
        getSubAccounts(selectedDebet, "debet");
      });
    } else {
      alert("Account əlavə edin");
    }
    setName("");
  };

  const addCreditSub = (e) => {
    e.preventDefault();
    if (name) {
      fetch(process.env.REACT_APP_BASE_URL + "account/addSub", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serial: selectedCredit,
          mySubAccount: name,
        }),
      }).then(() => {
        getSubAccounts(selectedCredit, "credit");
      });
    } else {
      alert("Account əlavə edin");
    }
    setName("");
  };

  const changeSubSubCredit = (e) => {
    console.log(e);
    setSelectedSubSubCreditAcc(e);
  };

  const changeSubSubDebet = (e) => {
    console.log(e);
    setSelectedSubSubDebetAcc(e);
  };

  const handleChangeDebet = (debet, mainHeader, header) => {
    setSelectedDebet(debet);
    setSelectedDebetMainHeader(mainHeader);
    setSelectedDebetHeader(header);
    setOpenDebet(false);
  };

  const handleChangeCredit = (credit, mainHeader, header) => {
    setSelectedCredit(credit);
    setSelectedCreditMainHeader(mainHeader);
    setSelectedCreditHeader(header);
    setOpenCredit(false);
  };

  const handleCloseCredit = () => {
    setOpenCredit(false);
  };

  const getSubSubAccounts = (serial, type) => {
    fetch(process.env.REACT_APP_BASE_URL + "account/getSubSub", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serial,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (type === "debet") {
          setSubSubAccount(data.mySub);
        } else if ((type = "credit")) {
          setSubSubCreditAccount(data.mySub);
        }
      });
  };

  const getSubAccounts = (serial, type) => {
    fetch(process.env.REACT_APP_BASE_URL + "account/getSub", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serial: serial,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (type === "debet") {
          setSubAccounts(data.mySub);
        } else if (type === "credit") {
          setCreditSubAccount(data.mySub);
        }
      });
  };

  React.useEffect(() => {
    if ((selectedDebetMainHeader, selectedDebetHeader, selectedDebet)) {
      getSubAccounts(selectedDebet, "debet");
    }
  }, [selectedDebet]);

  React.useEffect(() => {
    if ((selectedCreditMainHeader, selectedCreditMainHeader, selectedCredit)) {
      getSubAccounts(selectedCredit, "credit");
    }
  }, [selectedCredit]);

  React.useEffect(() => {
    if (selectedSubDebet) {
      getSubSubAccounts(selectedSubDebet, "debet");
    }
  }, [selectedSubDebet]);

  React.useEffect(() => {
    if (selectedSubCredit) {
      getSubSubAccounts(selectedSubCredit, "credit");
    }
  }, [selectedSubCredit]);
  React.useEffect(() => {
    setSaveSchema(false);
    setNameSchema("");
    setAmmountValidation("");
    setDebetValidation("");
    setCreditValidation("");
    setDestionationValidation("");
    setOperationValidation("");
    setSelectedCredit("");
    setSelectedDebet("");
    setAmount(0);
    setDestination("");
    setSelectedOperation("");
    setSelectedSubDebet("");
    setSelectedSubCredit("");
    setSelectedSubSubDebetAcc("");
    setSelectedSubSubCreditAcc("");
  }, [open]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const addOperationFunction = () => {
    if (!saveSchema || (saveSchema && nameSchema)) {
      fetch(process.env.REACT_APP_BASE_URL + "operation/add", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serial: "AA",
          operationDate: selectedDate,
          debetAccNo: selectedDebet,
          creditAccNo: selectedCredit,
          destination: destination,
          amount: amount,
          token: localStorage.getItem("token"),
          debetSub: selectedSubDebet,
          creditSub: selectedSubCredit,
          debetSubSubAccount: selectedDebetSubSubAcc,
          creditSubSubAccount: selectedSubSubCreditAcc,
        }),
      })
        .then(() => {
          getOperation();
        })
        .then(() => {
          if (saveSchema) {
            if (nameSchema) {
              addSchema();
              setOpen(false);
            } else {
              alert("Şablon adı qeyd edin");
            }
          } else {
            setOpen(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (saveSchema && !nameSchema) {
      alert("Şablon adı qeyd edin");
    }
  };

  React.useEffect(() => {
    if (destination) {
      setDestionationValidation("");
    }
    if (!destination) {
      setDestionationValidation("Təyinat yazın");
    }
  }, [destination]);

  React.useEffect(() => {
    if (parseInt(amount) === 0) {
      setAmmountValidation("0 dan fərqli");
    }
    if (parseInt(amount) !== 0) {
      setAmmountValidation("");
    }
  }, [amount]);

  React.useEffect(() => {
    if (debetValidation) {
      setDebetValidation("");
    }
  }, [selectedDebet]);

  React.useEffect(() => {
    if (creditValidation) {
      setCreditValidation("");
    }
  }, [selectedCredit]);

  React.useEffect(() => {
    if (operationValidation) {
      setOperationValidation("");
    }
  }, [selectedOperation]);

  const addOperation = () => {
    if (amount === 0 || !selectedDebet || !selectedCredit || !destination) {
      if (!selectedDebet) {
        setDebetValidation("Debet hesabı seçin");
      }
      if (!selectedCredit) {
        setCreditValidation("Credit hesabı seçin");
      }
      if (!destination.trim(" ")) {
        setDestionationValidation("Təyinat yazın");
      }
      if (parseInt(amount) === 0) {
        setAmmountValidation("0 dan fərqli");
      }
    } else {
      console.log("Bura geldi");
      addOperationFunction();
    }
  };

  const getAcconts = () => {
    fetch(process.env.REACT_APP_BASE_URL + "account/getAll", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const accountsCopy = structuredClone(data);
        setAccounts(accountsCopy);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getAcconts();
  }, []);

  const changeSubDebet = (value) => {
    setSelectedSubDebet(value);
  };

  const changeSubCredit = (value) => {
    setSelectedSubCredit(value);
  };

  return (
    <>
      <AddAccount
        open={openDebet}
        handleClose={handleCloseDebet}
        data={accounts}
        handleChange={handleChangeDebet}
        selected={selectedCredit}
      />
      <AddAccount
        open={openCredit}
        handleClose={handleCloseCredit}
        data={accounts}
        handleChange={handleChangeCredit}
        selected={selectedDebet}
      />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={css.operationModal}>
            <div className={css.accountContainer}>
              <div className={css.accValidation}>
                <div
                  className={css.accountInput}
                  onClick={() => {
                    setOpenDebet(true);
                  }}
                >
                  <div className={css.selectedAccount}>
                    {selectedDebet ? selectedDebet : "Debet"}
                  </div>
                  <div className={css.line}></div>
                  <div className={css.accImg}>
                    <img src={arrow} className={css.image} />
                  </div>
                </div>
                <span>{debetValidation}</span>
              </div>
              <Select
                className={css.selectSubAcc}
                style={{
                  width: 250,
                  height: 50,
                }}
                showSearch
                value={selectedSubDebet ? selectedSubDebet : undefined}
                onChange={changeSubDebet}
                placeholder="Alt kateqoriya"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Kateqoriya"
                        value={name}
                        onChange={onNameChange}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addDebetSub}
                      >
                        Əlavə et
                      </Button>
                    </Space>
                  </>
                )}
                options={subAccounts.map((item) => ({
                  label: item.serial + " " + item.subAccount,
                  value: item.serial,
                }))}
              />

              <Select
                className={css.selectSubAcc}
                style={{
                  width: 250,
                  height: 50,
                }}
                showSearch
                placeholder="Alt kateqoriya"
                value={
                  selectedDebetSubSubAcc ? selectedDebetSubSubAcc : undefined
                }
                onChange={changeSubSubDebet}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Kateqoriya"
                        value={name}
                        onChange={onNameChange}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addDebetSubSub}
                      >
                        Əlavə et
                      </Button>
                    </Space>
                  </>
                )}
                options={subSubAccount.map((item) => ({
                  label: item.serial + " " + item.subSubAccount,
                  value: item.serial,
                }))}
              />
            </div>
            <div className={css.accountContainer}>
              <div className={css.accValidation}>
                <div
                  className={css.accountInput}
                  onClick={() => {
                    setOpenCredit(true);
                  }}
                >
                  <div className={css.selectedAccount}>
                    {selectedCredit ? selectedCredit : "Kredit"}
                  </div>
                  <div className={css.line}></div>
                  <div className={css.accImg}>
                    <img src={arrow} className={css.image} />
                  </div>
                </div>
                <span>{creditValidation}</span>
              </div>
              <Select
                className={css.selectSubAcc}
                style={{
                  width: 250,
                  height: 50,
                }}
                value={selectedSubCredit ? selectedSubCredit : undefined}
                onChange={changeSubCredit}
                showSearch
                placeholder="Alt kateqoriya"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Kateqoriya"
                        value={name}
                        onChange={onNameChange}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addCreditSub}
                      >
                        Əlavə et
                      </Button>
                    </Space>
                  </>
                )}
                options={creditSubAccount.map((item) => ({
                  label: item.serial + " " + item.subAccount,
                  value: item.serial,
                }))}
              />
              <Select
                className={css.selectSubAcc}
                style={{
                  width: 250,
                  height: 50,
                }}
                showSearch
                value={
                  selectedSubSubCreditAcc ? selectedSubSubCreditAcc : undefined
                }
                onChange={changeSubSubCredit}
                placeholder="Alt kateqoriya"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Kateqoriya"
                        value={name}
                        onChange={onNameChange}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addCreditSubSub}
                      >
                        Əlavə et
                      </Button>
                    </Space>
                  </>
                )}
                options={subSubCreditAccount.map((item) => ({
                  label: item.serial + " " + item.subSubAccount,
                  value: item.serial,
                }))}
              />
            </div>

            {!isSchema && (
              <div className={css.dateContainer}>
                <div className={css.dateWithCheck}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className={css.DateTimePicker}
                      label="Əməliyyat tarixi"
                      value={selectedDate}
                      onChange={handleDateChange}
                      renderInput={(props) => <TextField {...props} />}
                      showTimeInput
                      readOnly={enable}
                      maxDate={today}
                      minDate={minDate}
                    />
                  </LocalizationProvider>
                  <label>
                    <input
                      type="checkbox"
                      defaultChecked={enable}
                      onClick={() => setEnable(!enable)}
                    />
                    <p>Cari zaman</p>
                  </label>
                </div>
                <div className={css.ammountValidation}>
                  <input
                    className={css.amount}
                    placeholder="Məbləğ"
                    type="number"
                    value={amount || ""}
                    min={0}
                    onChange={(event) => {
                      let value = event.target.value;
                      if (value < 0 && value !== "") {
                        value = 0;
                      }
                      setAmount(value);
                    }}
                  />

                  <span>{ammountValidation}</span>
                </div>
              </div>
            )}

            <div className={css.destinationValidation}>
              <textarea
                className={css.destination}
                value={destination}
                onChange={(event) => setDestination(event.target.value)}
                placeholder="Təyinat"
              />
              <span>{destinationValidation}</span>
            </div>
            {isSchema && (
              <div className={css.destinationValidation}>
                <textarea
                  className={css.destination}
                  value={nameSchema}
                  onChange={(e) => {
                    setNameSchema(e.target.value);
                  }}
                  placeholder="Şablon adı"
                />
                {/* <span>{destinationValidation}</span> */}
              </div>
            )}

            <ButtonMaterial
              style={{
                backgroundColor: "#1E88E5",
                color: "white",
                height: "2.7rem",
                width: "45%",
                minWidth: "80px",
                maxWidth: "250px",
              }}
              onClick={() => {
                if (!isSchema) {
                  addOperation();
                } else {
                  addSchema();
                }
              }}
            >
              Əlavə et
            </ButtonMaterial>

            {!isSchema && (
              <label className={css.checkBoxContainer}>
                <input
                  type="checkbox"
                  defaultChecked={saveSchema}
                  onClick={() => setSaveSchema(!saveSchema)}
                />
                <p>Şablon olaraq yadda saxla</p>
                {saveSchema && (
                  <input
                    value={nameSchema}
                    className={css.schemaInput}
                    onChange={(e) => {
                      setNameSchema(e.target.value);
                    }}
                    placeholder="Şablon adı"
                  />
                )}
              </label>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}
