export function getDescendantProp(obj, path, isArr = false) {
  if (!path) {
    throw new Error("Invalid path");
  }

  const arr = path.split(".");

  if (isArr) {
    obj = obj[arr.shift()];

    while (!Array.isArray(obj)) {
      const fieldName = arr.shift();

      obj = obj[fieldName];

      // if (Array.isArray(obj)) break;
    }

    return obj?.map((item) => {
      let tmp = [...arr];
      while (tmp && tmp.length) {
        const fieldName = tmp.shift();

        item = item[fieldName];
        if (!item) break;
      }
      return item;
    });
  }

  while (arr && arr.length) {
    const fieldName = arr.shift();

    obj = obj[fieldName];

    if (!obj) break;
  }

  return obj;
}
