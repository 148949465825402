import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 10px 0px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  outline: 0;
  width: 100%;
  background-color: #1b2635;
  padding: 5px 10px;
  border: 1px solid #333;
  border-radius: 5px;
  color: white;
  transition: border-color 0.3s ease-in-out;
  position: relative;
  z-index: 1;

  &:focus,
  &:hover {
    border-color: #fff;
  }
`;

const Ul = styled.ul``;

const Li = styled.li``;

export default function TableFooter({
  totalRows,
  limit,
  offset,
  fontSize,
  fontSizeHandler,
  paginationHandler,
}) {
  const [input, setInput] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    paginationHandler(page);
  }, [page]);

  useEffect(() => {
    offset === 0 && setInput(1);
    offset === 0 && setPage(1);
  }, [offset]);

  const countOfPages = (total, count) => {
    return Math.ceil(total / count);
  };

  const onChangeHandler = (e, value) => {
    setPage(value);
    setInput(value);
  };

  const inputOnChangeHandler = (e) => {
    const value = +e.target.value;
    if (isNaN(value) || value <= 0) {
      setInput("");
      return;
    }
    setPage(value);
    setInput(value);
  };
  const beetweenRowsOrder = () => {
    const start = (page - 1) * limit;
    const end = Math.min(totalRows, offset + limit);

    return `${start + 1} - ${end}`;
  };

  return (
    <Container>
      <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
        <Ul>
          <Li>
            Mövcud aralıq:{" "}
            <span style={{ fontWeight: "bold" }}>{beetweenRowsOrder()}</span>
          </Li>
          <Li>
            Ümumi say: <span style={{ fontWeight: "bold" }}>{totalRows}</span>
          </Li>
        </Ul>
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <label style={{ marginRight: "5px" }}>Səhifə:</label>
        <Input
          onChange={inputOnChangeHandler}
          value={input}
          maxLength={
            Math.ceil(countOfPages(totalRows, limit)).toString().length
          }
          style={{ height: "100%", width: "50px" }}
        />
        <Pagination
          count={Math.ceil(countOfPages(totalRows, limit))}
          onChange={onChangeHandler}
          page={page}
        />
      </div>
    </Container>
  );
}
