import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddOperation from "../../../components/modal/addOperation";
import { useNavigate } from "react-router-dom";
import Table from "./../../../components/table/Table";
import Column from "./../../../models/table/Column";
import { OPERATORS_CONTAINING } from "../../../constants";
import SchemaModal from "../../../components/modal/Schema";

const Divider = styled(MuiDivider)(spacing);

export default function Operations() {
  const [operation, setOperation] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSchemas, setOpenSchemas] = useState(false);
  const [deleted, setDeleted] = useState(true);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totoalRows, setTotalRows] = useState(0);
  const [selectedSchema, setSelectedSchema] = useState("");
  const [changeSchema, setChangeSchema] = useState(false);
  const [dynamicWidth, setDynamicWidth] = useState(
    (window.innerWidth / 100) * 75
  );
  const columns = [
    new Column({
      headerName: "Əməliyyat No.",
      path: "operationNo",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      headerName: "Tarix",
      path: "operationDate",
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      headerName: "Debet Hesab No.",
      path: "debetSerial",
    }),
    new Column({
      headerName: "Debet hesab adı",
      path: "debetText",
    }),
    new Column({
      headerName: "Kredit hesab No.",
      path: "creditSerial",
    }),
    new Column({
      headerName: "Kredit hesab adı.",
      path: "creditText",
    }),
    new Column({
      headerName: "Təyinat",
      path: "destination",
    }),
    new Column({
      headerName: "Məbləğ",
      path: "amount",
    }),
    new Column({
      headerName: "İstifadəçi",
      path: "user",
    }),
    new Column({
      headerName: "Debet alt hesab",
      path: "debetSubAccount",
    }),
    new Column({
      headerName: "Kredit alt hesab",
      path: "creditSubAccount",
    }),

    new Column({
      headerName: "Debet detallı alt hesab",
      path: "debetSubSubAccount",
    }),
    new Column({
      headerName: "Kredit detallı alt hesab",
      path: "creditSubSubAccount",
    }),
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSchema = () => {
    setOpenSchemas(false);
  };

  const getOperation = () => {
    fetch(process.env.REACT_APP_BASE_URL + "operation/getAll", {
      method: "POST",
      body: JSON.stringify({
        offset: offset,
        limit: limit,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = [];
        if (data) {
          setTotalRows(data.operationsLength);
          arr = data.filteredOperations;
          arr.map((element) => {
            const updatedDate = new Date(element.operationDate);
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            };
            const formattedDate = new Intl.DateTimeFormat(
              "en-GB",
              options
            ).format(updatedDate);
            element.operationDate = formattedDate;
          });
        }
        setOperation(arr);
      });
  };

  useEffect(() => {
    getOperation();
  }, [limit, offset]);

  const deleteOpearation = async (id, token, password) => {
    await fetch(process.env.REACT_APP_BASE_URL + "operation/remove", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        operationId: id,
        token: token,
        password: password,
      }),
    }).then(async (response) => {
      if (response.ok) {
        setDeleted(!deleted);
      } else if (!response.ok) {
        const data = await response.json();
        alert(data.message);
      }
    });
    getOperation();
  };

  return (
    <React.Fragment>
      {openSchemas && (
        <SchemaModal
          handleCloseSchema={handleCloseSchema}
          openSchemas={openSchemas}
          setSelectedSchema={setSelectedSchema}
          setOpen={setOpen}
          setChangeSchema={setChangeSchema}
          changeSchema={changeSchema}
        />
      )}

      <AddOperation
        open={open}
        handleClose={handleClose}
        setOpen={setOpen}
        getOperation={getOperation}
        selectedSchema={selectedSchema}
        changeSchema={changeSchema}
      />
      <Typography variant="h3" gutterBottom display="inline">
        Əməliyyatlar
      </Typography>

      <Divider my={6} />

      <div
        style={{
          width: dynamicWidth,
        }}
      >
        <Table
          data={operation || []}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
          isLoading={loading}
          totalRows={totoalRows}
          columns={columns}
          hideDownload={false}
          setOpen={setOpen}
          setOpenSchemas={setOpenSchemas}
          deleteFunction={deleteOpearation}
          deleted={deleted}
          isDelete={true}
        />
      </div>
    </React.Fragment>
  );
}
