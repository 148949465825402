import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddOperation from "../../../components/modal/addOperation";
import { useNavigate } from "react-router-dom";
import Table from "./../../../components/table/Table";
import Column from "./../../../models/table/Column";
import { OPERATORS_CONTAINING } from "../../../constants";
import { getSchemas } from "../../../components/schema/schema";

const Divider = styled(MuiDivider)(spacing);

export default function Schemas() {
  const [schemas, setSchemas] = useState(null);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [totoalRows, setTotalRows] = useState(10);
  const columns = [
    new Column({
      headerName: "Şablon adı",
      path: "name",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      headerName: "Tarix",
      path: "date",
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      headerName: "Debet hesab adı.",
      path: "debetText",
    }),
    new Column({
      headerName: "Debet Hesab No.",
      path: "debetAccNo",
    }),
    new Column({
      headerName: "Debet alt hesab",
      path: "subDebetAccNo",
    }),
    new Column({
      headerName: "Debet detallı alt hesab",
      path: "subSubDebetAccNo",
    }),
    new Column({
      headerName: "Kredit hesab adı.",
      path: "creditText",
    }),

    new Column({
      headerName: "Kredit hesab No.",
      path: "creditAccNo",
    }),
    new Column({
      headerName: "kredit alt hesab",
      path: "subCreditAccNo",
    }),
    new Column({
      headerName: "Kredit detallı alt hesab",
      path: "subSubCreditAccNo",
    }),
    new Column({
      headerName: "Təyinat",
      path: "destination",
    }),
    new Column({
      headerName: "İstifadəçi",
      path: "user",
    }),
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getSchemas(setSchemas, limit, offset, setTotalRows);
  }, [limit, offset, open]);

  return (
    <React.Fragment>
      <AddOperation
        open={open}
        handleClose={handleClose}
        setOpen={setOpen}
        isSchema={true}
      />
      <Typography variant="h3" gutterBottom display="inline">
        Şablonlar
      </Typography>

      <Divider my={6} />

      <div
        style={{
          width: "1165px",
        }}
      >
        <Table
          data={schemas || []}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          setLimit={setLimit}
          totalRows={totoalRows}
          columns={columns}
          hideDownload={false}
          setOpen={setOpen}
          isDelete={true}
          isSchema={true}
        />
      </div>
    </React.Fragment>
  );
}
