import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import FilterModel from "../../../../../../../../models/table/Filter/FilterModel";
import ButtonCustom from "../../../../../../../ui/Button/ButtonCustom";
import { Delete } from "@mui/icons-material";
import Eraser from "../../../../../../../../assets/icons/Eraser/index";
import ValueInput from "./ValueInput";
import { OPERATORS_CONTAINING } from "../../../../../../../../constants";

const expand = keyframes`
from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const shrink = keyframes`
from {
    width: 100%;
  }
  to {
    width: 50%;
  }
`;

const shrinkMore = keyframes`
from {
    width: 50%;
  }
  to {
    width: 33.333%;
  }
`;

const appearAnimation = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 45px;
  }
`;

const exitAnimation = keyframes`
  from {
    opacity: 0.1;
    height: 45px;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &.delete-animation {
    animation: ${exitAnimation} 0.5s forwards ease-in-out;
  }
`;

// animation: ${appearAnimation} 0.5s forwards ease-in-out;

const SelectContainer = styled.div`
  width: 50%;
  height: 100%;
  padding: 5px 0px;
  margin-left: 2.5px;
  transform-origin: left;
  transition: all 0.5s;

  ${({ $expanded }) =>
    $expanded &&
    css`
      animation: ${expand} 0.5s forwards ease-in-out;
    `}
  ${({ $shrinked }) =>
    $shrinked &&
    css`
      animation: ${shrink} 0.5s forwards ease-in-out;
    `}
  ${({ $shrinkedMore }) =>
    $shrinkedMore &&
    css`
      animation: ${shrinkMore} 0.5s forwards ease-in-out;
    `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
`;

const ButtonContainer = styled.div`
  margin: 0px 2.5px;
  height: 100%;
  width: 50%;
`;

const Select = styled.select`
  appearance: none;
  outline: 0;
  cursor: pointer;
  width: 100%;
  background-color: #1b2635;
  padding: 5px 10px;
  border: 1px solid #333;
  border-radius: 5px;
  color: white;
  transition: all 0.5s ease-in-out;

  &:after {
    background-color: black;
  }

  &:hover {
    border-color: #fff;
  }
  &:active {
    border-color: #fff;
  }
  option {
    color: inherit;
    background-color: #320a28;
  }
`;

const CustomSelect = styled.div`
  position: relative;
  width: 100%; /* Adjust the width as needed */

  select {
    appearance: none;
    outline: 0;
    cursor: pointer;
    width: 100%;
    background-color: #1b2635;
    padding: 5px 10px;
    border: 1px solid #333;
    border-radius: 5px;
    color: white;
    transition: border-color 0.3s ease-in-out;
    position: relative;
    z-index: 1;

    &:focus,
    &:hover {
      border-color: #fff;
    }
  }

  /* Styling the dropdown indicator */
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 10px;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: white transparent transparent;
    pointer-events: none;
    z-index: 2;
  }
`;

const FilterItem = ({
  columns,
  filter = new FilterModel({ column: "", operator: "", value: "" }),
  index,
  onChange,
  onDelete,
  disabled,
  disableEditRow,
  resetRow,
}) => {
  const [deleted, setDeleted] = useState(false);
  // this state for control UI rendering logic.
  // not for form data
  const [column, setColumn] = useState();
  const [operator, setOperator] = useState([]);

  useEffect(() => {
    if (!filter.column) return;

    const founded = columns.find(
      (i) => i.path === filter.column || i.filterColumnKey === filter.column
    );
    founded && setColumn(founded);
    founded &&
      founded?.filterType &&
      founded?.filterType === OPERATORS_CONTAINING.SINGLE_OPTION &&
      onChange("operator", index, OPERATORS_CONTAINING.SINGLE_OPTION[0].key);
    founded && setOperator(founded.filterType);
  }, [filter.column]);

  const handleDelete = (id) => {
    setDeleted(true);
    setTimeout(() => {
      onDelete(id); // Trigger deletion after the exit animation
    }, 500); // Adjust the delay to match the animation duration
  };

  const width = !filter.column
    ? "100%"
    : filter.column && !filter.operator
    ? "50%"
    : "33%";

  return (
    <Container key={index} className={deleted ? "delete-animation" : ""}>
      <SelectContainer
        // $expanded={Boolean(!filter.column)}
        // $shrinked={
        //   Boolean(filter.column && !filter.operator) ||
        //   column?.filterType === OPERATORS_CONTAINING.SINGLE_OPTION
        // }
        // $shrinkedMore={
        //   Boolean(filter.operator) &&
        //   column?.filterType !== OPERATORS_CONTAINING.SINGLE_OPTION
        // }
        style={{
          width:
            filter?.operator === OPERATORS_CONTAINING.SINGLE_OPTION[0].key
              ? "50%"
              : width,
        }}
      >
        <CustomSelect>
          <select
            style={{ width: "100%" }}
            value={filter?.column}
            onChange={(e) => {
              onChange("column", index, e.target.value);
            }}
            disabled={disabled}
          >
            <option hidden>Sütun seçin...</option>
            {columns.map(
              (item, index) =>
                !item.isStaticColumn && (
                  <option
                    key={item.id}
                    value={
                      item.filterColumnKey ? item.filterColumnKey : item.path
                    }
                  >
                    {item.headerName}
                  </option>
                )
            )}
          </select>
        </CustomSelect>
      </SelectContainer>

      {filter.column &&
        column?.filterType !== OPERATORS_CONTAINING.SINGLE_OPTION && (
          <SelectContainer
            style={{
              //   display: !filter.column && "none",
              //   opacity: !filter.column && 0,
              //   pointerEvents: !filter.column && "none",
              width: filter.column && !filter.operator ? "50%" : "33%",
            }}
            // $shrinked={Boolean(filter.column)}
            // $shrinkedMore={Boolean(filter.operator)}
          >
            <CustomSelect>
              <select
                style={{ width: "100%" }}
                value={filter?.operator}
                onChange={(e) => {
                  onChange("operator", index, e.target.value);
                }}
                disabled={disabled}
              >
                <option hidden>Operator seçin...</option>
                {operator?.map((item, index) => (
                  <option key={item.key} value={item.key}>
                    {item.name}
                  </option>
                ))}
              </select>
            </CustomSelect>
          </SelectContainer>
        )}

      <SelectContainer
        style={{
          display: !filter.operator && "none",
          opacity: !filter.operator && 0,
          pointerEvents: !filter.operator && "none",
          width: filter.operator
            ? filter.operator === OPERATORS_CONTAINING.SINGLE_OPTION[0].key
              ? "50%"
              : "33%"
            : 0,
        }}
        // $expanded={Boolean(!filter.value)}
        // $shrinkedMore={Boolean(filter.operator)}
        // $shrinked={column?.filterType === OPERATORS_CONTAINING.SINGLE_OPTION}
      >
        <ValueInput
          column={column}
          filter={filter}
          fetchHandler={column?.fetchFilterValues && column.fetchFilterValues}
          fetchHandler2={
            column?.fetchFilterValuesV2 && column?.fetchFilterValuesV2
          }
          dataGridName={column?.dataGridName}
          structureKey={column?.dependencieKeysOfFilter?.structures}
          filterType={operator}
          onChange={(event) => {
            onChange("value", index, event.target.value);
          }}
          values={column?.filterValues && column.filterValues}
          disabled={disabled}
          value={filter.value}
        />
      </SelectContainer>

      {!disableEditRow &&
        (index === 0 ? null : (
          <ButtonsContainer>
            <ButtonContainer>
              <ButtonCustom
                style={{ padding: 0 }}
                styleContainer={{ width: "30px" }}
                children={<Eraser style={{ fill: "#fff", width: "13px" }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  resetRow(index);
                }}
              />
            </ButtonContainer>
            <ButtonContainer>
              <ButtonCustom
                children={<Delete fontSize="10px" />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(filter.id);
                }}
                style={{ backgroundColor: "#710c04", padding: 0 }}
                styleContainer={{ width: "30px" }}
              />
            </ButtonContainer>
          </ButtonsContainer>
        ))}
    </Container>
  );
};

export default FilterItem;
